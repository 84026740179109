import React, { Component } from 'react'
// import { Link } from "react-router-dom";
import { Form, Input, Col, Row, Button } from 'antd';
import { Trans, withTranslation } from 'react-i18next';

class Footer extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        const onFinish = (values) => {
            console.log('Success:', values);
        };

        const onFinishFailed = (errorInfo) => {
            console.log('Failed:', errorInfo);
        };

        return (
            <Row id='footer' align="middle" style={{ backgroundColor: '#05001A', padding: '10px', color: 'white', fontSize: '16px' }}>
                <Col md={10} sm={24} align="middle">
                    <Row>
                        <Col span={4} align="right"  style={{padding: '0 20px'}} className='nota-footer-img'>
                            <img className="img-fluid" style={{ maxWidth: "95%" }} src="logo192.png" alt="" />
                        </Col>
                        <Col span={20} align="left">
                            <p className="nota-footer-title"><Trans i18nKey='footer.headquarters' /></p>
                            <p><Trans i18nKey='footer.company' /></p>
                            <p><Trans i18nKey='footer.location' /></p>
                        </Col>
                    </Row>
                </Col>

                <Col md={14} sm={24} align="left">
                    <Row>
                        <Col md={12} style={{ padding: '0px 20px 0px 0px' }} align="left">
                            <p className="nota-footer-title">Email</p>
                            <p>contact@notastudio.vn</p>
                            <p className="nota-footer-title">Hotline</p>
                            <p>0918559869</p>
                        </Col>
                        <Col md={11}  style={{ padding: '0px 20px 0px 0px' }}>
                            <Row style={{ paddingBottom: '10px' }}>
                                <p className="nota-footer-title"><Trans i18nKey='footer.contact' /></p>
                                <Input.Group compact>
                                    <Input style={{ width: 'calc(100% - 65px)' }} defaultValue="" />
                                    <Button type='primary' style={{ backgroundColor: '#6CD34A' }}>Send</Button>
                                </Input.Group>
                            </Row>
                            <Row>
                                <p className="nota-footer-title"><Trans i18nKey='footer.time' /></p>
                                <p><Trans i18nKey='footer.time-detail' /></p>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}

export default withTranslation() (Footer);
